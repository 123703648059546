import { defaultParameters, defaultProducts } from '../config';
import { isEmpty } from '../utils/validation';
import axiosInstance from './axiosInstance';

const PROD = process.env.NODE_ENV === 'production';

export const getParameters = async (hashSalesCenter) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/parameters${isEmpty(hashSalesCenter) ? '' : `?sale-center=${hashSalesCenter}`}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        if (PROD) {
            data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
        } else {
            return defaultParameters;
        }
    }

    return data;
}

export const getProvinces = async () => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/provinces`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const searchVehicle = async (branch, license) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/get-vehicle-data/${branch}/${license}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getFaqContent = async () => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/faq-content`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;

}

export const newQuote = async (body = {}) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/new-quote`, body);

        if (response.data) {
            data = response.data
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const vehicleUsages = async (autoCod = 0) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/description-by-infoautocod/${autoCod}`);

        if (response.data) {
            data = response.data
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getProducts = async () => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/products`);

        if (response.data) {
            data = response.data
        }
    } catch (e) {
        if (PROD) {
            data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
        } else {
            return defaultProducts;
        }
    }

    return data;
}

export const getBrands = async (branchId) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/brands/${branchId}`);

        if (response.data) {
            data = response.data
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getModelsByBrand = async (brandId, year) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/models/${brandId}/${year}`);

        if (response.data) {
            data = response.data
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getPersonalDataByDni = async (dni) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/get-person-data/${dni}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getVehicleUsages = async (branch, modeloCodigo) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/get-vehicle-usages/${branch}/${modeloCodigo}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getHashIframe = async (param) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/get-hash-payment?id=${param}-DIBI&programa=encriptar`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getStatusPayment = async (param) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/get-status-payment?id=${param}&programa=estatusPago`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getDataByPostalCode = async (postalCode) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/data-by-postal-code/${postalCode}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const updateQuoteHeader = async (quoteHeaderId, body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/quotations/${quoteHeaderId}/update`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const updateClientQuoteHeader = async (quoteHeaderId, body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/quotations/${quoteHeaderId}/update-client`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const updateClient = async (quoteHeaderId, body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/quotations/${quoteHeaderId}/download/update-client`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const newPolicy = async (body, file) => {
    let data = [];

    const formData = new FormData();
    formData.append('policy', JSON.stringify(body));

    if (body.payment) {
        formData.append('eventId', Date.now());
    }

    if (!isEmpty(file)) {
        formData.append('noBearingCertificate', file, file.name);
    }

    try {
        const response = await axiosInstance.post(`/new-policy`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const validateVehicle = async (body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/validate-vehicle`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const validateVehicleByLicense = async (body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/validate-vehicle-by-license`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getBase64Pdf = async (id, type, eventId) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/print-documents/${id}/${type}/${eventId}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getProvisionaryDoc = async (id) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/pdf/${id}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getNationalities = async () => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/nacionalities`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getCitiesByPostalCode = async (postalCode) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/cities/${postalCode}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const createTicket = async (ticketBody) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/new-ticket`, ticketBody);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const createContactTicket = async (ticketBody) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/new-contact-ticket`, ticketBody);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const hireByPhone = async (body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/hire-by-phone`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const cancelRequest = async (body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/baja`, JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const updateTermsAndNews = async (quotationId, body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/policy/${quotationId}/update-terms-news`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const valueByModelYear = async (model, year, section) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/value-by-model-year/${model}/${year}/${section}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getCoverageLegends = async (section, target) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/coverage/legends/${section}/${target}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getSalesCenterParameters = async (hash) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/sales-center/${hash}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const checkYearSelected = async (year) => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/check-year-selected/${year}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const viewPdf = async (quoteId, body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/download-quotation/${quoteId}`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const updateTrackingFile = async (quotationId, quotationCoverageId, file) => {
    let data = [];

    const formData = new FormData();
    formData.append('body', JSON.stringify({ quotation_id: quotationId, quotation_coverage_id: quotationCoverageId }));

    if (!isEmpty(file)) {
        formData.append('trackingFile', file, file.name);
    }

    try {
        const response = await axiosInstance.post(`/update-tracking-file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getBanks = async () => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/payment-process/fetch-banks`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const setCbuPayment = async (body) => {
    let data = [];

    try {
        const response = await axiosInstance.post(`/policy/update-payment-method`, body);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getPaymentMethods = async (productId, saleCenterHash = '') => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/products-sections/${productId}/get-payment-methods${isEmpty(saleCenterHash) ? '' : `?sale-center=${saleCenterHash}`}`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}

export const getServerDateTime = async () => {
    let data = [];

    try {
        const response = await axiosInstance.get(`/get-server-datetime`);

        if (response.data) {
            data = response.data;
        }
    } catch (e) {
        data.push({ error: `${e.code} at ${e.config.url}: ${e.message}.` });
    }

    return data;
}