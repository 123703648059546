import React, { createContext, useContext, useEffect, useState } from 'react';
import * as service from '../../services';
import { getItemStorage, lStorage, removeItemStorage } from '../storage';
import { defaultContactBody, defaultPersonForm, defaultQuoteForm, defaultTicketBody, defaultVehicleValue, defaultSaleCenterBody, defaultMaxValueGnc, defaultMinValueGnc, defaultPaymentBody } from '../../config';
import { scrollToTop } from '../../utils/functions';
import { isEmpty, validateFormExpired } from '../../utils/validation';
import { crypt } from '../../utils/crypt';

const MyContext = createContext();

// TODO: controlar desactivación de datacar y afip.
// TODO: handle steps de forma dinámica
export const MyContextProvider = ({ children }) => {
    const [parameters, setParameters] = useState(null);
    const [parametersSaleCenter, setParametersSaleCenter] = useState(defaultSaleCenterBody);
    const [salesCenterHash, setSalesCenterHash] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [products, setProducts] = useState([]);
    const [vehicleData, setVehicleData] = useState(defaultVehicleValue);
    const [faqContent, setFaqContent] = useState([]);
    const [quote, setQuote] = useState(null);
    const [activeStep, setActiveStep] = useState(1);
    const [potencialPolicy, setPotencialPolicy] = useState(null);
    const [quoteForm, setQuoteForm] = useState(defaultQuoteForm);
    const [personData, setDataPerson] = useState(defaultPersonForm);
    const [hashIframe, setHashIframe] = useState('');
    const [paymentProcessed, setPaymentProcessed] = useState(false);
    const [policy, setPolicy] = useState(null);
    const [errorPolicy, setErrorPolicy] = useState(null);
    const [documentDownloaded, setDocumentDownloaded] = useState(false);
    const [hireByPhone, setHireByPhone] = useState(false);
    const [currentBranch, setCurrentBranch] = useState(3);
    const [quotationExpired, setQuotationExpired] = useState(false);
    const [checkPayment, setCheckPayment] = useState(false);
    const [initialErrors, setInitialErrors] = useState([]);
    const [procedenciaFromQr, setProcedenciaFromQr] = useState('');
    const [activeProduct, setActiveProduct] = useState(null);

    const [sellPhoneNumber, setSellPhoneNumber] = useState('');
    const [openingHour, setOpeningHour] = useState('');
    const [maxDaysNoBearingCertificate, setMaxDaysNoBearingCertificate] = useState(0);
    const [maxSizeNoBearingCertificate, setMaxSizeNoBearingCertificate] = useState(0);
    const [sumAsegDesde, setSumAsegDesde] = useState(0);
    const [sumAsegHasta, setSumAsegHasta] = useState(0);
    const [maxValueGnc, setMaxValueGnc] = useState(defaultMaxValueGnc);
    const [minValueGnc, setMinValueGnc] = useState(defaultMinValueGnc);

    useEffect(() => {
        const vehicle = getItemStorage('L', 'modelObject');
        const quote = getItemStorage('L', 'newQuote');
        const active = getItemStorage('L', 'activeStep');
        const potencial = getItemStorage('L', 'potencialPolicy');
        const form = getItemStorage('L', 'quoteForm');
        const hash = getItemStorage('L', 'hashIframe');
        const payment = getItemStorage('L', 'paymentProcessed');
        const pol = getItemStorage('L', 'policy');
        const errorPol = getItemStorage('L', 'errorPolicy');
        const downloaded = getItemStorage('L', 'document_downloaded');
        const phone = getItemStorage('L', 'hire_by_phone');
        let branch = getItemStorage('L', 'active_branch');
        const product = getItemStorage('L', 'activeProduct');

        // datos iniciales
        const incorrectPaymentData = getItemStorage('L', 'incorrect_payment_data');
        const formExpired = getItemStorage('L', 'form_expired');
        const paymentDeclined = getItemStorage('L', 'payment_declined');
        // ---------------

        (async () => {
            const resultFormExpired = await validateFormExpired();

            if (vehicle) {
                setVehicleData(vehicle);
            } else {
                setVehicleData(defaultVehicleValue);
            }

            if (quote) {
                setQuote(quote);
            }

            if (active) {
                setActiveStep(active);
            }

            if (potencial) {
                setPotencialPolicy(potencial);
            }

            if (form) {
                setQuoteForm(form);

                if (form.dataPerson) {
                    setDataPerson(form.dataPerson);
                }
            }

            if (hash) {
                setHashIframe(hash);
            }

            if (payment) {
                setPaymentProcessed(payment);
            } else {
                lStorage('paymentProcessed', false);
            }

            if (incorrectPaymentData === null) lStorage('incorrect_payment_data', false);
            if (formExpired === null) lStorage('form_expired', false);
            if (paymentDeclined === null) lStorage('payment_declined', false);

            if (pol) {
                setPolicy(pol);
            }

            if (errorPol) {
                setErrorPolicy(errorPol);
            }

            if (downloaded) {
                setDocumentDownloaded(downloaded);
            }

            if (phone) {
                setHireByPhone(phone);
            }

            if (branch) {
                setCurrentBranch(branch);
            } else {
                branch = 3;
                lStorage('active_branch', branch);
            }

            if (product) {
                setActiveProduct(product);
            }

            const hashSalesCenter = getSalesCenterHash();

            const errors = [];

            const responseParameters = await service.getParameters(hashSalesCenter);

            if (!isEmpty(responseParameters[0].error)) {
                errors.push(responseParameters[0].error);
                setParameters([]);
            } else {
                setParameters(responseParameters);

                setSellPhoneNumber(responseParameters.find(e => e.tag === 'TELEFONO' && e.enabled === 1).value);
                setOpeningHour(responseParameters.find(e => e.tag === 'HORARIO' && e.enabled === 1).value);
                setMaxDaysNoBearingCertificate(responseParameters.find(e => e.tag === 'ANTIGUEDAD_CERTIFICADO_NO_RODAMIENTO' && e.enabled === 1).value);
                setMaxSizeNoBearingCertificate(responseParameters.find(e => e.tag === 'PESO_MAX_CERTIFICADO_NO_RODAMIENTO' && e.enabled === 1).value);
                setSumAsegDesde(responseParameters.find(e => e.tag === `SUMA_ASEGURADA_DESDE_${branch}` && e.enabled === 1).value);
                setSumAsegHasta(responseParameters.find(e => e.tag === `SUMA_ASEGURADA_HASTA_${branch}` && e.enabled === 1).value);

                let gncConfig = defaultMaxValueGnc;
                gncConfig.porc = responseParameters.find(e => e.tag === 'TOPE_GNC_MAXIMO_PORCENTUAL' && e.enabled === 1).value;
                gncConfig.nominal = responseParameters.find(e => e.tag === 'TOPE_GNC_MAXIMO_NOMINAL' && e.enabled === 1).value;
                setMaxValueGnc(gncConfig);

                gncConfig = defaultMinValueGnc;
                gncConfig.porc = responseParameters.find(e => e.tag === 'TOPE_GNC_MINIMO_PORCENTUAL' && e.enabled === 1).value;
                gncConfig.nominal = responseParameters.find(e => e.tag === 'TOPE_GNC_MINIMO_NOMINAL' && e.enabled === 1).value;
                setMinValueGnc(gncConfig);
            }

            const responseProducts = await service.getProducts();

            if (!isEmpty(responseProducts[0].error)) {
                errors.push(responseProducts[0].error);
                setProducts([]);
            } else {
                setProducts(responseProducts);
                handleActiveProduct(responseProducts.find(e => e.section === branch));
            }

            if (resultFormExpired === true) {
                setQuotationExpired(true);
            }

            await service.getProvinces()
                .then((response) => {
                    if (!isEmpty(response[0].error)) {
                        errors.push(response[0].error);
                        setProvinces([]);
                    } else {
                        setProvinces(response);
                    }
                })
                .catch((err) => {
                    setProvinces([]);
                });

            await service.getFaqContent()
                .then((response) => {
                    if (!isEmpty(response[0].error)) {
                        errors.push(response[0].error);
                        setFaqContent([]);
                    } else {
                        setFaqContent(response);
                    }
                })
                .catch((err) => {
                    setFaqContent([]);
                });

            setInitialErrors(errors);
        })();
    }, []);

    useEffect(() => {
        if (quotationExpired === true) {
            clearQuote();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quotationExpired]);

    useEffect(() => {
        if (!isEmpty(parametersSaleCenter.id) && products.length > 0 && isEmpty(quote)) {
            let array = [];
            let arrayAux = [];

            array = parametersSaleCenter.products.map(e => {
                return e.product_id;
            });

            products.forEach(element => {
                if (array.includes(element.id)) {
                    arrayAux.push(element);
                }
            });

            if (arrayAux.length === 1) {
                const product = arrayAux[0];

                handleActiveProduct(product);
                handleCurrentBranch(product.section);
            }
        }
    }, [parametersSaleCenter, products, quote]);

    const getSalesCenterHash = () => {
        const searchParams = new URLSearchParams(window.location.search);
        // console.log(searchParams.get('sales-center'));
        // console.log(searchParams.get('fromQr'));
        let salesCenter = searchParams.get('sale-center');
        let fromQr = searchParams.get('fromQr');

        setSalesCenterHash(salesCenter);
        setProcedenciaFromQr(fromQr);

        if (!isEmpty(salesCenter)) {
            lStorage('sale-center', salesCenter);
        } else {
            salesCenter = getItemStorage('L', 'sale-center');

            if (!isEmpty(salesCenter)) {
                setSalesCenterHash(salesCenter);
            }
        }

        if (!isEmpty(fromQr)) {
            lStorage('salesCenterFromQr', 1);
        } else {
            fromQr = getItemStorage('L', 'salesCenterFromQr');

            if (!isEmpty(fromQr)) {
                setProcedenciaFromQr(fromQr);
            }
        }

        const bodySaleCenter = getItemStorage('L', 'obj_sale_center');
        if (bodySaleCenter) {
            setParametersSaleCenter(bodySaleCenter);
        } else {
            setParametersSaleCenter(defaultSaleCenterBody);
        }

        return salesCenter;
    }

    const handleSaleCenterBody = (obj) => {
        setParametersSaleCenter(obj);
        lStorage('obj_sale_center', obj);
    }

    useEffect(() => {
        (async () => {
            if (!isEmpty(salesCenterHash)) {
                try {
                    const { body } = await service.getSalesCenterParameters(salesCenterHash);

                    if (isEmpty(body)) {
                        handleSaleCenterBody(defaultSaleCenterBody);

                        setSalesCenterHash('');
                        lStorage('sale-center', '');
                    } else {
                        handleSaleCenterBody(body);
                    }
                } catch (e) {
                    handleSaleCenterBody(defaultSaleCenterBody);
                }
            }
        })();
    }, [salesCenterHash]);

    const validateYear = (param) => {
        const minYear = parameters.find(item => item.tag === 'MIN_ANIO_VIGENTE').value;
        const maxYear = parameters.find(item => item.tag === 'MAX_ANIO_VIGENTE').value;

        return (parseInt(param) >= parseInt(minYear) && parseInt(param) <= parseInt(maxYear));
    }

    const searchVehicleByLicense = async (branch, license) => {
        if (branch && license) {
            try {
                const response = await service.searchVehicle(branch, license);

                if (license !== vehicleData.Patente) {
                    setQuoteForm(defaultQuoteForm);
                    lStorage('quoteForm', defaultQuoteForm);
                }

                if (response.error === false) {
                    let vigency = false;

                    const data = response.body ? response.body : response;

                    const responseValidationYear = validateYear(data.Anio);

                    const bodyValidateVehicle = { domain: license, section: branch };
                    const responseValidation = await service.validateVehicleByLicense(bodyValidateVehicle);

                    if (responseValidation.error === false && responseValidation.vigency === false) {
                        vigency = false;
                    } else {
                        vigency = true;
                    }

                    if (responseValidationYear) {
                        const newBody = { ...data, ...{ QuoteType: 'P', CeroKm: false, Vigency: vigency } };

                        const responseSumAssured = await service.valueByModelYear(newBody.Infoauto.model_id, newBody.Anio, currentBranch);

                        if (!isEmpty(responseSumAssured)) {
                            if (!isNaN(parseFloat(responseSumAssured)))
                                newBody.Infoauto.sumAssured = responseSumAssured;
                            else
                                newBody.Infoauto.sumAssured = 0;
                        } else {
                            newBody.Infoauto.sumAssured = 0;
                        }

                        await lStorage('modelObject', newBody);
                        setVehicleData(newBody);

                        return { msg: '', error: false };
                    } else {
                        const minYear = parameters.find(item => item.tag === 'MIN_ANIO_VIGENTE').value;
                        const maxYear = parameters.find(item => item.tag === 'MAX_ANIO_VIGENTE').value;

                        return { title: '¡Ups! No podemos seguir con la cotización', msg: `El año de fabricación del vehículo no es admitido por la compañía, debe estar comprendido entre ${minYear} y ${maxYear}.`, error: true };
                    }
                } else {
                    setVehicleData(defaultVehicleValue);
                    lStorage('modelObject', defaultVehicleValue);

                    return { msg: 'Podés cotizar desde el botón COTIZAR SIN PATENTE', error: true };
                }
            } catch (error) {
                return { msg: JSON.stringify(error), error: true };
            }
        }
    }

    const newQuote = async ({ usage_code, postal_code, rastreo, has_alarm, has_gnc, value_gnc, city_id, recaptcha_token, payment_method }) => {
        const responsePostalCode = await service.getDataByPostalCode(postal_code);

        if (responsePostalCode.error === false) {
            const { province_id } = responsePostalCode.body;

            let usageCode = parseInt(vehicleData.Rama) === 3 ? usage_code : '';
            let typeUsage = parseInt(vehicleData.Rama) === 3 ? null : usage_code;

            const data = {
                city_id: city_id,
                province_id: province_id,
                motorcycle_model_id: vehicleData.Infoauto.model_id ?? vehicleData.ModeloCodigo,
                year: vehicleData.Anio,
                cero_km: vehicleData.CeroKm,
                rastreo: rastreo,
                has_alarm: has_alarm,
                has_gnc: has_gnc,
                value_gnc: value_gnc,
                usage_code: usageCode, // el usage_code es '' cuando la rama es moto
                type_usage: typeUsage, // type_usage 1, particular. type_usage 2, comercial. esto se aplica para motos
                promotion_code: null,
                motorcycle_value: 0,
                postal_code: null,
                product_id: products.find(item => parseInt(item.section) === parseInt(currentBranch)).id,
                client: {
                    name: '',
                    last_name: '',
                    area_code: '11',
                    cellphone: '12345678',
                    email: ''
                },
                sale_center: salesCenterHash,
                procedencia: procedenciaFromQr === 1 || procedenciaFromQr === true ? 'fromQr' : '',
                recaptcha_token: recaptcha_token,
                plan: payment_method
            }

            try {
                const response = await service.newQuote(data);

                if (response.id !== undefined) {
                    setQuote(response);
                    lStorage('newQuote', response);

                    handleStepQuotation(2);

                    let time = {};

                    try {
                        time = await service.getServerDateTime();
                    } catch (e) {
                        time.body = response.created_at;
                    }

                    let now = response.created_at;

                    if (!isEmpty(time.body)) {
                        now = time.body;
                    }

                    lStorage('date_created', now);

                    return { msg: '', error: false };
                } else {
                    setQuote(null);
                    lStorage('newQuote', null);
                    handleStepQuotation(1);

                    return { msg: response.message ?? 'No se obtuvieron coberturas para el vehiculo cotizado', error: true };
                }
            } catch (error) {
                return { msg: JSON.stringify(error), error: true };
            }
        } else {
            return { msg: responsePostalCode.msg, error: true };
        }
    }

    const clearQuote = () => {
        const branch = getItemStorage('L', 'active_branch');
        const product = products.find(e => parseInt(e.section) === parseInt(branch));

        lStorage('activeStep', 1);
        lStorage('paymentProcessed', false);
        lStorage('incorrect_payment_data', false);
        lStorage('form_expired', false);
        lStorage('payment_declined', false);
        lStorage('hire_by_phone', false);
        lStorage('document_downloaded', false);
        lStorage('active_branch', branch);
        lStorage('activeProduct', product);

        removeItemStorage('L', 'newQuote');
        removeItemStorage('L', 'potencialPolicy');
        removeItemStorage('L', 'quoteForm');
        removeItemStorage('L', 'modelObject');
        removeItemStorage('L', 'hashIframe');
        removeItemStorage('L', 'policy');
        removeItemStorage('L', 'errorPolicy');
        removeItemStorage('L', 'date_created');
        removeItemStorage('L', 'contact_form_registered');

        setQuote(null);
        setPotencialPolicy(null);
        setActiveStep(1);
        setQuoteForm(defaultQuoteForm);
        setVehicleData(defaultVehicleValue);
        setDataPerson(defaultPersonForm);
        setHashIframe('');
        setPaymentProcessed(false);
        setPolicy(null);
        setErrorPolicy(null);
        setHireByPhone(false);
        setDocumentDownloaded(false);
        setCurrentBranch(branch);
        setActiveProduct(product);
    }

    const handleVehicleData = (data) => {
        const newObj = { ...vehicleData, ...data };

        setVehicleData(newObj);
        lStorage('modelObject', newObj);
    }

    const handlePotencialPolicy = (policy) => {
        setPotencialPolicy(policy);
        lStorage('potencialPolicy', policy);
        handleStepQuotation(3);
    }

    const handleStepQuotation = (numberStep) => {
        scrollToTop();
        setActiveStep(numberStep);
        lStorage('activeStep', numberStep);
    }

    const handleQuoteForm = (newForm) => {
        const form = { ...quoteForm, ...newForm };

        setQuoteForm(form);
        lStorage('quoteForm', form);

        setDataPerson({ ...personData, ...newForm.dataPerson });
    }

    const handleSearchPersonByDni = async (dni) => {
        if (dni !== '' && dni.length >= 8) {
            await service.getPersonalDataByDni(dni)
                .then((response) => {
                    setDataPerson({ ...defaultPersonForm, ...{ DocumentoNumero: dni } });

                    if (response.error === false) {
                        const { postalCode } = quoteForm;
                        const { body: { Direccion: { CodigoPostal } } } = response;

                        if (!isEmpty(postalCode) && !isEmpty(CodigoPostal)) {
                            const { body } = response;

                            if (postalCode !== CodigoPostal) {
                                body.Direccion.CodigoPostal = '';
                                body.Direccion.CalleNombre = '';
                                body.Direccion.CalleNumero = '';
                                body.Direccion.CallePiso = '';
                                body.Direccion.CalleDepto = '';
                            }

                            setDataPerson(body);
                        }
                    }
                });
        }
    }

    const handleContractQuoteForm = async (dataForm, dataPerson) => {
        try {
            const obj = { ...quoteForm, ...dataForm, dataPerson: { ...dataForm.dataPerson, ...dataPerson } };
            handleQuoteForm(obj);

            const bodyValidateVehicle = { domain: vehicleData.Patente, chassis: obj.chasisNumber, document: obj.dataPerson.DocumentoNumero, quotation_coverage_id: potencialPolicy.id };

            const responseValidation = await service.validateVehicle(bodyValidateVehicle);

            if (responseValidation.error === false) {
                if (parseInt(obj.dataPerson.Direccion.CodigoPostal) !== parseInt(quoteForm.postalCode)) {
                    return { error: true, msg: `El código postal de la cotización (CP${quoteForm.postalCode}) no coincide con el código postal ingresado (CP${obj.dataPerson.Direccion.CodigoPostal}). Revise este dato e intente nuevamente.` };
                }

                const responseUpdate = await handlePersonQuote(obj.dataPerson);

                if (responseUpdate.error === false) {
                    handleStepQuotation(4);
                    return { error: false, msg: '' };
                } else return { error: true, msg: 'Ocurrió un error al continuar con la contratación. Intente nuevamente más tarde.' };
            } else {
                let errorMsg = responseValidation.message ?? 'Ocurrió un error al continuar con la contratación. Intente nuevamente más tarde.';

                return { error: true, msg: errorMsg };
            }
        } catch (e) {
            return { error: true, msg: 'Ocurrió un error al continuar con la contratación. Intente nuevamente más tarde.' };
        }
    }

    const handlePersonQuote = async (dataPerson, partialContent = false) => {
        const { DocumentoNumero, EMail, Nombre, Apellido, Area, Telefono, FechaNacimiento, Sexo, Nacionalidad, Direccion: { CodigoPostal, CalleNombre, CalleNumero, CallePiso, CalleDepto } } = dataPerson;

        let newFechaNacimiento = '';
        if (FechaNacimiento.length > 0 && FechaNacimiento !== '') {
            const [año, mes, dia] = FechaNacimiento.split('-');
            newFechaNacimiento = `${parseInt(dia)}/${parseInt(mes)}/${año}`;
        }

        const body = {
            document: DocumentoNumero,
            email: EMail,
            name: Nombre,
            last_name: Apellido,
            area_code: `${Area}`,
            cellphone: `${Telefono}`,
            birthday: newFechaNacimiento,
            nacionality_id: Nacionalidad,
            city_id: quote.city.id,
            province_id: quote.province.id,
            postal_code: parseInt(CodigoPostal),
            address_street: CalleNombre,
            address_number: CalleNumero,
            address_floor: CallePiso,
            address_apartment: CalleDepto,
            gender: Sexo === '1' ? 'M' : (Sexo === '2' ? 'F' : ''),
            civil_status_id: 1, // soltero
            activity_id: 1 // empleado bajo relación de dependencia
        };

        try {
            let response;

            if (partialContent === true)
                response = await service.updateClient(quote.id, body);
            else
                response = await service.updateClientQuoteHeader(quote.id, body);

            if (response.error === false) {
                return { error: false, msg: 'Datos del cliente actualizados correctamente.' };
            } else {
                return { error: true, msg: response.message ?? 'Ha ocurrido un error interno del servidor. Asegurese que todos los datos hayan sido cargados correctamente.' };
            }
        } catch (err) {
            return { error: true, msg: 'Error al actualizar los datos del cliente' };
        }
    }

    const handleHashIframe = (data = null) => {
        setHashIframe(data);
        lStorage('hashIframe', data);
    }

    const handlePaymentProcess = async (activeBranch, terms, news) => {
        const termsUpdated = await handleTermsAndNews(terms, news);

        if (termsUpdated === true) {
            const seccion = `0${activeBranch}`;
            let identificadorClave = '2';
            const clave = quote?.id;
            const monto = potencialPolicy?.feeValue;

            // eslint-disable-next-line eqeqeq
            const activeProduct = products.find(e => e.section == activeBranch);
            if (activeProduct) {
                if (activeProduct.has_objects) {
                    identificadorClave = '4';
                }
            }

            let inspeccion = 0;
            if (potencialPolicy.inspecciona) {
                if (potencialPolicy.inspecciona === 'ASEGURADO-APP' || potencialPolicy.inspecciona === 'ASEGURADO') inspeccion = 1;
            }

            const aniomesdia = new Date().toISOString().slice(0, 10).replace('T', '').replace(/-/g, '/');
            const horaminutosegundo = (new Date()).toTimeString().split(' ')[0];
            const fechaCreacion = aniomesdia + ' ' + horaminutosegundo;

            const param = `1-${seccion}-${identificadorClave}-${clave}-${monto}00-12-${inspeccion}-${fechaCreacion}`;

            try {
                const response = await service.getHashIframe(param);

                if (response.error === false) {
                    handleHashIframe(response.body);

                    return { msg: '', error: false, body: response.body };
                } else {
                    const ticketBody = defaultTicketBody;
                    ticketBody.reason = 'pre_process_iframe_error';
                    ticketBody.comment = `Error: ${response.message}`;
                    ticketBody.gateway_error = true;
                    ticketBody.quotation_coverage_id = potencialPolicy.id;

                    await service.createTicket(ticketBody);

                    return { msg: 'No se ha podido procesar correctamente el hash de pago.', error: true };
                }
            } catch (err) {
                return { msg: JSON.stringify(err), error: true };
            }
        } else {
            return { msg: 'Hubo un error al actualizar los términos y condiciones', error: true };
        }
    }

    const handleNewPolicy = async (paymentData = null) => {
        const bodyPayment = paymentData !== null ? paymentData : {};

        let newFechaNacimiento = '';
        if (personData.FechaNacimiento.length > 0 && personData.FechaNacimiento !== '') {
            const [año, mes, dia] = personData.FechaNacimiento.split('-');
            newFechaNacimiento = `${parseInt(dia)}/${parseInt(mes)}/${año}`;
        }

        const bodyPolicy = {
            client: {
                document: personData.DocumentoNumero,
                email: personData.EMail,
                name: personData.Nombre,
                last_name: personData.Apellido,
                area_code: `${personData.Area}`,
                cellphone: `${personData.Telefono}`,
                birthday: newFechaNacimiento,
                nacionality_id: personData.Nacionalidad,
                city_id: quote.city.id,
                province_id: quote.province.id,
                postal_code: parseInt(personData.Direccion.CodigoPostal),
                address_street: personData.Direccion.CalleNombre,
                address_number: personData.Direccion.CalleNumero,
                address_floor: personData.Direccion.CalleDepto,
                address_apartment: personData.Direccion.CallePiso,
                gender: personData.Sexo === '1' ? 'M' : (personData.Sexo === '2' ? 'F' : ''),
                civil_status_id: 1,
                activity_id: 1
            },
            motorcycle: {
                domain: vehicleData.Patente,
                motor_number: quoteForm.motorNumber,
                motor_chassis: quoteForm.chasisNumber,
                motorcycle_model_id: vehicleData.Infoauto.model_id,
                motorcycle_year: vehicleData.Anio
            },
            policyLife: null,
            payment: bodyPayment,
            inspecciona: null,
            operationCode: quote.operationCode,
            quotation_coverage_id: potencialPolicy.id,
            sale_center: salesCenterHash
        }

        const response = await service.newPolicy(bodyPolicy, isEmpty(paymentData) ? quoteForm.noBearingCertificate : null);

        return response;
    }

    useEffect(() => {
        if (paymentProcessed === true) {
            const responsePayment = getItemStorage('L', 'response_payment');
            removeItemStorage('L', 'response_payment');

            const { bank_id, documento, empresa_tarjeta, numero_tarjeta, estatusPago, fecha_vencimiento } = responsePayment;

            (async () => {
                const bodyPayment = defaultPaymentBody;

                bodyPayment.card_number = crypt(numero_tarjeta) ?? '';
                bodyPayment.code_bank = bank_id ?? '';
                bodyPayment.bank = empresa_tarjeta ?? '';
                bodyPayment.titular_name = personData.Nombre;
                bodyPayment.titular_last_name = personData.Apellido;
                bodyPayment.titular_document = documento ?? '';
                bodyPayment.expires_in = fecha_vencimiento ?? '';
                // eslint-disable-next-line eqeqeq
                bodyPayment.statusPago = estatusPago == '5' ? 'P' : (estatusPago == '1' ? 'A' : '');

                const responsePolicy = await handleNewPolicy(bodyPayment);

                const { gnc } = quoteForm;

                clearQuote();

                if (responsePolicy.id) {
                    setPolicy(responsePolicy);

                    const obj = { ...responsePolicy, ...{ gnc_included: gnc } };

                    lStorage('policy', obj);
                } else {
                    setPolicy(null);
                    setErrorPolicy(responsePolicy);
                    lStorage('errorPolicy', responsePolicy);

                    const bodyTicket = defaultTicketBody;
                    bodyTicket.comment = `Error en Emision Final: ${responsePolicy.message}`;
                    bodyTicket.reason = 'final_emit_error';
                    bodyTicket.quotation_coverage_id = potencialPolicy.id;
                    bodyTicket.patent = vehicleData.Patente;
                    bodyTicket.gateway_error = true;

                    await service.createTicket(bodyTicket);
                }

                setSalesCenterHash('');
                removeItemStorage('L', 'sale-center');

                setProcedenciaFromQr('');
                removeItemStorage('L', 'salesCenterFromQr');

                setActiveStep(6);
                lStorage('activeStep', 6);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentProcessed]);

    const newPolicyFromCbu = async (data) => {
        const bodyPayment = defaultPaymentBody;
        bodyPayment.payment_method = 4;
        bodyPayment.cbu = crypt(data.cbu);
        bodyPayment.titular_name = `${data.name}${isEmpty(data.last_name) ? '' : ` ${data.last_name}`}`;
        bodyPayment.titular_document = data.dni;

        const responsePolicy = await handleNewPolicy(bodyPayment);

        if (responsePolicy.id) {
            const { gnc } = quoteForm;

            clearQuote();

            setPolicy(responsePolicy);

            const obj = { ...responsePolicy, ...{ gnc_included: gnc } };

            lStorage('policy', obj);

            setSalesCenterHash('');
            removeItemStorage('L', 'sale-center');

            setProcedenciaFromQr('');
            removeItemStorage('L', 'salesCenterFromQr');

            setActiveStep(6);
            lStorage('activeStep', 6);
        } else {
            const bodyTicket = defaultTicketBody;
            bodyTicket.comment = `Error en Emision Final: ${responsePolicy.message}`;
            bodyTicket.reason = 'final_emit_error';
            bodyTicket.quotation_coverage_id = potencialPolicy.id;
            bodyTicket.patent = vehicleData.Patente;
            bodyTicket.gateway_error = true;

            await service.createTicket(bodyTicket);
        }

        return responsePolicy;
    }

    useEffect(() => {
        // Si el hashIframe !== '' entonces tendría que emitir la comprobación del estado del pago
        setCheckPayment(hashIframe !== '');
    }, [hashIframe]);

    const checkStatusPayment = async () => {
        const payment = getItemStorage('L', 'paymentProcessed');

        if (hashIframe !== '' && !payment) {
            const response = await service.getStatusPayment(hashIframe);

            if (response.body.estatusPago) {
                const status = parseInt(response.body.estatusPago);

                switch (status) {
                    case 1:
                    case 5:
                        lStorage('incorrect_payment_data', false);
                        lStorage('form_expired', false);
                        lStorage('payment_declined', false);

                        // necesito comprobar si el pago ya fue procesado. En ese caso, no tengo que entrar acá
                        if (!payment) {
                            lStorage('paymentProcessed', true);
                            lStorage('response_payment', response.body);
                            setPaymentProcessed(true);

                            // El pago fue satisfactorio. Tendría que avanzar a la página de finalización de la cobertura
                            return { msg: '', error: false, body: {} };
                        } else {
                            return { msg: 'Pago procesado', error: false, body: {} };
                        }
                    case 9:
                        const paymentDeclined = getItemStorage('L', 'payment_declined');

                        if (!paymentDeclined) {
                            const ticketBody = defaultTicketBody;
                            ticketBody.reason = 'payment_error';
                            ticketBody.comment = `Error: Pago rechazado - ${response.body.mensaje}`;
                            ticketBody.gateway_error = true;
                            ticketBody.quotation_coverage_id = potencialPolicy.id;

                            await service.createTicket(ticketBody);

                            lStorage('payment_declined', true);
                        }

                        return { msg: '', error: false, body: 9 };
                    case 10:
                        // clearQuote();
                        const formExpired = getItemStorage('L', 'form_expired');

                        if (!formExpired) {
                            const ticketBody = defaultTicketBody;
                            ticketBody.reason = 'payment_error';
                            ticketBody.comment = `Error: ${response.body.mensaje}`;
                            ticketBody.gateway_error = true;
                            ticketBody.quotation_coverage_id = potencialPolicy.id;

                            handleHashIframe('');
                            await service.createTicket(ticketBody);

                            lStorage('form_expired', true);
                        }

                        return { msg: '', error: false, body: 10 };
                    case 0:
                        const incorrectPaymentData = getItemStorage('L', 'incorrect_payment_data');

                        if (!incorrectPaymentData) {
                            const ticketBody = defaultTicketBody;
                            ticketBody.reason = 'payment_error';
                            ticketBody.comment = `Error: Datos de tarjeta invalidos - ${response.body.mensaje}`;
                            ticketBody.gateway_error = true;
                            ticketBody.quotation_coverage_id = potencialPolicy.id;

                            await service.createTicket(ticketBody);

                            lStorage('incorrect_payment_data', true);
                        }

                        return { msg: '', error: true, body: 0 };
                    default:
                        return { msg: '', error: true, body: response };
                }
            } else {
                return { msg: '', error: true, body: {} };
            }
        } else {
            return { msg: '', error: true, body: {} };
        }
    }

    const handleOnlineQuotation = async () => {
        const body = { coverageId: potencialPolicy.coverageId, coverageValueId: null, quotationCoverageId: potencialPolicy.id };

        const response = await service.updateQuoteHeader(quote.id, body);

        if (response.error === false) {
            const responseNewPolicy = await handleNewPolicy();

            if (responseNewPolicy.error === false) {
                handleStepQuotation(5);

                return { msg: '', error: false };
            } else {
                const bodyTicket = defaultTicketBody;
                bodyTicket.comment = `Error al emitir parcialmente: ${responseNewPolicy.message}`;
                bodyTicket.reason = 'partial_emit_error';
                bodyTicket.quotation_coverage_id = potencialPolicy.id;
                bodyTicket.patent = vehicleData.Patente;

                await service.createTicket(bodyTicket);

                return { msg: responseNewPolicy.message ?? 'Ha ocurrido un error al emitir la póliza. Intente nuevamente más tarde.', error: true };
            }
        } else {
            return { msg: response.message, error: true };
        }
    }

    const handleDocDownload = (b) => {
        setDocumentDownloaded(b);
        lStorage('document_downloaded', b);
    }

    const getPdfDataDownload = async () => {
        const response = await service.getBase64Pdf(policy.id, policy.status, Date.now());

        if (response.filename) {
            const { base64, mime, filename } = response;

            const binaryString = window.atob(base64);

            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([bytes], { type: mime });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
            document.body.removeChild(link);

            handleDocDownload(true);
        } else {
            handleDocDownload(false);
        }
    }

    const handlePhoneQuotation = async (dataForm) => {
        const obj = { ...quoteForm, ...dataForm };
        handleQuoteForm(obj);

        const { Area, Telefono, Nombre, Apellido, EMail, DocumentoNumero } = personData;

        const bodyQuotation = {
            name: Nombre,
            last_name: Apellido,
            phone: Area + Telefono,
            email: EMail,
            document: DocumentoNumero,
            patent: vehicleData.Patente,
            quotation_id: potencialPolicy.quotationId,
            quotation_coverage_id: potencialPolicy.id,
            event_id: Date.now()
        };

        const response = await service.hireByPhone(bodyQuotation);

        // if (response.error === false) {
        //     const responseUpdate = await service.updateTrackingFile(potencialPolicy.quotationId, potencialPolicy.id, dataForm.trackingFile);

        //     console.log(responseUpdate);
        // }

        return { msg: response?.message ?? 'Su solicitud no ha sido generada correctamente.', error: response?.error ?? true };
    }

    const handleSuccessPhoneQuotation = async () => {
        clearQuote();

        setSalesCenterHash('');
        removeItemStorage('L', 'sale-center');

        setProcedenciaFromQr('');
        removeItemStorage('L', 'salesCenterFromQr');

        handleStepQuotation(6);
        lStorage('hire_by_phone', true);
        setHireByPhone(true);
    }

    const handleCurrentBranch = (branchId) => {
        setCurrentBranch(branchId);
        lStorage('active_branch', branchId);
    }

    const cancelRequest = async (cancellationForm) => {
        let vehicleType;

        switch (parseInt(cancellationForm.Ramo)) {
            case 3:
                vehicleType = 'Auto';
                break;
            case 4:
                vehicleType = 'Moto';
                break;
            default:
                vehicleType = '';
                break;
        }

        const body = {
            client: {
                areaCode: cancellationForm.Area,
                dni: cancellationForm.Dni,
                email: cancellationForm.Email,
                lastName: cancellationForm.Apellido,
                name: cancellationForm.Nombre,
                patente: cancellationForm.Patente,
                phone: cancellationForm.Telefono,
                recaptcha_token: cancellationForm.RecaptchaToken,
                vehicleType: vehicleType
            }
        };

        const response = await service.cancelRequest(body);

        return { error: response.error ?? false, msg: response.message ?? 'Hemos registrado su solicitud. A la brevedad, será contactado' };
    }

    const handleManualQuotation = async (data) => {
        const response = await service.valueByModelYear(data.ModeloCodigo, data.Anio, currentBranch);

        if (!isEmpty(response)) {
            data.Infoauto.sumAssured = response;
        } else {
            data.Infoauto.sumAssured = 0;
        }

        const newObj = { ...vehicleData, ...data };

        setVehicleData(newObj);
        lStorage('modelObject', newObj);
    }

    const handleContactForm = async (form) => {
        const contactForm = getItemStorage('L', 'contact_form_registered');

        if (isEmpty(contactForm)) {
            try {
                const body = defaultContactBody;
                body.phone = form.Telefono;
                body.email = form.EMail;
                body.message = 'Nuevo contacto desde el formulario Quiero que me contacten del e-commerce.';
                body.sales_center = salesCenterHash;

                const response = await service.createContactTicket(body);

                if (response.error === false) {
                    lStorage('contact_form_registered', 1);
                }

                return { error: response.error, msg: 'Su solicitud ha sido registrada con éxito. Nos pondremos en contacto a la brevedad.' };
            } catch (error) {
                return { error: true, msg: 'Ha ocurrido un error al registrar su solicitud. Intente nuevamente más tarde.' };
            }
        } else {
            return { error: false, msg: 'Ya se encuentra registrada una solicitud.' };
        }
    }

    const handleViewPdf = async () => {
        const body = {
            vehicleData: vehicleData,
            quoteForm: quoteForm
        }

        const response = await service.viewPdf(quote.id, body);

        if (response.filename) {
            const { base64, mime, filename } = response;

            const binaryString = window.atob(base64);

            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([bytes], { type: mime });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
            document.body.removeChild(link);

            return { msg: '', error: false };
        } else {
            return { msg: 'Ha ocurrido un error con la descarga de la cotización.', error: true };
        }
    }

    useEffect(() => {
        if (!isEmpty(parameters)) {
            const defaultSaleCenterHash = parameters.find(e => e.tag === 'DEFAULT_SALE_CENTER_HASH' && e.enabled === 1);

            if (!isEmpty(defaultSaleCenterHash)) {
                setSalesCenterHash(defaultSaleCenterHash.value);
                lStorage('sale-center', defaultSaleCenterHash.value);
            }
        }
    }, [parameters]);

    useEffect(() => {
        if (!isEmpty(activeProduct) && !isEmpty(parameters)) {
            if (!isEmpty(activeProduct.section)) {
                setSumAsegDesde(parameters.find(e => e.tag === `SUMA_ASEGURADA_DESDE_${activeProduct.section}` && e.enabled === 1).value);
                setSumAsegHasta(parameters.find(e => e.tag === `SUMA_ASEGURADA_HASTA_${activeProduct.section}` && e.enabled === 1).value);
            }
        }
    }, [activeProduct, parameters]);

    const handleActiveProduct = (p) => {
        setActiveProduct(p);
        lStorage('activeProduct', p);
    }

    const handleTermsAndNews = async (terms, news) => {
        try {
            const { updated } = await service.updateTermsAndNews(potencialPolicy.quotationId, { terms: terms, news: news });

            return updated;
        } catch (e) {
            return false;
        }
    }

    const sharedState = {
        parameters,
        provinces,
        products,
        salesCenterHash,
        vehicleData,
        faqContent,
        quote,
        activeStep,
        potencialPolicy,
        quoteForm,
        personData,
        hashIframe,
        policy,
        errorPolicy,
        documentDownloaded,
        hireByPhone,
        currentBranch,
        quotationExpired,
        checkPayment,
        initialErrors,
        sellPhoneNumber,
        openingHour,
        parametersSaleCenter,
        maxDaysNoBearingCertificate,
        maxSizeNoBearingCertificate,
        sumAsegDesde,
        sumAsegHasta,
        maxValueGnc,
        minValueGnc,
        activeProduct,
        functions: {
            validateYear,
            searchVehicleByLicense,
            newQuote,
            clearQuote,
            handleVehicleData,
            handlePotencialPolicy,
            handleStepQuotation,
            handleQuoteForm,
            handleSearchPersonByDni,
            handleContractQuoteForm,
            handlePaymentProcess,
            checkStatusPayment,
            handleOnlineQuotation,
            getPdfDataDownload,
            handlePhoneQuotation,
            handleSuccessPhoneQuotation,
            handleCurrentBranch,
            handleHashIframe,
            cancelRequest,
            handleManualQuotation,
            handlePersonQuote,
            handleContactForm,
            handleViewPdf,
            handleSaleCenterBody,
            newPolicyFromCbu,
            handleActiveProduct,
            handleTermsAndNews
        }
    };

    return <MyContext.Provider value={sharedState}>{children}</MyContext.Provider>;
};

export const useMyContext = () => {
    return useContext(MyContext);
};